import { useQuery } from '@apollo/react-hooks';
import { Spinner } from '@a-cloud-guru/kermit';
import { Container } from 'components/container';
import { QueryError } from 'components/data';

const defaultRenderLoading = () => <Container><Spinner size="3rem" /></Container>;
const defaultRenderError = (error, refetch) => <QueryError refetch={refetch} error={error} />;

const render = ({
  loading,
  error,
  data,
  refetch
}) => renderers => {
  if(renderers.loading !== false && loading) {
    const renderLoading = renderers.loading || defaultRenderLoading;
    return renderLoading();
  }

  if (renderers.error !== false && error) {
    const renderError = renderers.error || defaultRenderError;
    return renderError(error, refetch);
  }

  return renderers.data(data);
};

const useQueryRender = ({
  query,
  variables
}) => {
  const { loading, error, data, refetch, ...rest } = useQuery(query, {
    variables
  });

  return [
    render({ data, loading, error, refetch }),
    { loading, error, data, refetch, ...rest }
  ];
};

export { useQueryRender };