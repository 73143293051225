import get from 'lodash/get';
import { useProfile } from 'hooks/use-profile';

const useBetaFeature = (featureName) => {
  const { organisation } = useProfile();

  const requests = get(organisation, 'betaFeatureRequests');

  if (!requests) {
    return { loading: true, approved: undefined };
  }

  const featureRequest = requests.find(({ feature }) => feature === featureName);

  return { loading: false, approved: get(featureRequest, 'status') === 'APPROVED' };
};

export { useBetaFeature };
